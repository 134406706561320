import PropTypes from 'prop-types'
import React from 'react'

const GridContentItem = ({ item, layout, svgIcon, title, copy }) => {
  const regexSingleStar = /\*(.*?)\*/g

 
    return (
      <article className={`${layout === 'Icon Top' ? 'text-center' : null} md:px-6`}>
       {svgIcon ? <div
        className={`${layout === 'Icon Top' ? 'mx-auto' : null} w-14 h-14 p-3 rounded-full bg-brand-500 shadow text-white`} 
          dangerouslySetInnerHTML={{ __html: svgIcon }}
        /> : null} 
        <h3
          className="font-display font-semibold text-xl lg:text-2xl my-2 leading-8"
          dangerouslySetInnerHTML={{
            __html: title.replace(regexSingleStar, function(x) {
              return (
                "<span class='text-blue-600'>" +
                regexSingleStar.exec(title)[1] +
                '</span>'
              )
            }),
          }}
        />
        <p className="text-gray-700">{copy}</p>
      </article>
    )
  }  
 

GridContentItem.propTypes = {
  title: PropTypes.string,
  layout: PropTypes.string,
  copy: PropTypes.string,
  svgIcon: PropTypes.string,
}

GridContentItem.defaultProps = {
  title: 'Grid Item',
  layout: 'Icon Top',
  copy: '',
  svgIcon: null,
}
export default GridContentItem
