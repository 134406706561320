import React from 'react'
import ContactForm from '../contact-form'
import HeroRatingStars from '../hero-rating-stars'
import TrophyBanner from '../trophy-banner'
import backgroundImg from '../../assets/beams---header.png'
 

function HomePageHero() {
  let checkMarkList = [
    'Industry-Leading SEO',
    'Utilizing Latest Technologies',
    'Responsive, Blazing-Fast Websites',
  ]
  return (
    <>
    <div className='relative' 
    style={{
paddingTop: "10.75rem",
marginTop:"-8.75rem",
background: `url('${backgroundImg}')`,
backgroundPosition: "calc(50% + 220px) -50px",
backgroundSize: "2000px 90%",

}}>
   
 

  <div
      className="py-8 md:py-12 bg-cover h-auto relative border-b"
      // style={{ background: `url(${pattern})` }}
    >
<div className="container mx-auto px-4 md:px-6  flex flex-wrap items-center  ">
          <div className="w-full md:w-1/2 lg:w-2/3 z-10 pr-0 md:pr-10">
          <div>
          <div class="flex items-center space-x-2"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 501.551 501.551" class="shadow-xl w-6"><polygon fill="#FF7058" points="296.751,412.735 204.8,412.735 220.473,379.298 234.057,347.951 267.494,347.951   281.078,379.298 "></polygon><path fill="#FFD15C" d="M355.265,412.735h-208.98c-11.494,0-20.898,9.404-20.898,20.898v67.918h249.731v-67.918  C376.163,422.139,366.759,412.735,355.265,412.735z"></path><path fill="#F1543F" d="M281.078,379.298c-8.359,4.18-16.718,8.359-25.078,11.494l-5.224,1.045l-5.225-2.09  c-8.359-3.135-16.718-7.314-25.078-11.494l13.584-31.347h32.392L281.078,379.298z"></path><g><path fill="#F8B64C" d="M0,57.469v42.841C0,204.8,64.784,294.661,155.69,332.278c29.257,12.539,61.649,18.808,95.086,18.808   s65.829-6.269,95.086-18.808c90.906-37.616,155.69-127.478,155.69-231.967V57.469H0z M459.755,100.31   c0,50.155-17.763,96.131-48.065,132.702c-38.661,45.975-96.131,76.278-160.914,76.278s-122.253-29.257-160.914-76.278   c-29.257-35.527-48.065-82.547-48.065-132.702v-1.045H458.71v1.045H459.755z"></path><path fill="#F8B64C" d="M413.78,0H87.771c-7.314,0-12.539,6.269-12.539,12.539c0,7.314,5.224,13.584,12.539,13.584H413.78   c7.314,0,12.539-6.269,12.539-12.539C426.318,6.269,421.094,0,413.78,0z"></path></g><path fill="#FFD15C" d="M413.78,26.122v112.849c0,104.49-64.784,199.576-163.004,237.192  c-98.22-38.661-163.004-132.702-163.004-238.237V26.122H413.78z"></path><polygon fill="#FFFFFF" points="250.776,73.143 272.718,140.016 342.727,140.016 286.302,181.812 307.2,248.686   250.776,206.89 194.351,248.686 215.249,181.812 158.824,140.016 228.833,140.016 "></polygon><rect x="87.771" y="26.122" fill="#FFC952" width="324.963" height="15.673"></rect></svg><span class="font-semibold text-yellow-500">Top Rated Marketing Agency in Sacramento</span></div>
          <h1
            className={`mt-4 text-3xl font-extrabold tracking-[-0.04em] text-black sm:text-5xl sm:leading-[3.5rem]`}
          >
            Sacramento's Premier Web Design &amp; Digital Marketing Company
          </h1>
          <span className={`text-lg py-4 block text-gray-700`}>
            We pride ourselves on providing innovative website development and
            internet marketing services that will drive our clients' business
            towards growth.
          </span>
          {checkMarkList.map((x, i) => (
            <span key={i} className="py-3 flex  text-white font-semibold">
              <svg
                className="h-6 w-6 p-1 bg-green-500 rounded-full"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>{' '}
              <span className={`ml-2 text-lg text-gray-800`}>{x}</span>
            </span>
          ))} 
             <HeroRatingStars dark={false} className="lg:-mr-3"/>
          </div>
            </div>

            <div
              className="w-full md:w-1/2 lg:w-1/3 form-offset"
              // style={{ marginBottom: FORMOFFSETPX * -1 + "px" }}
            >
              <div className="bg-white rounded-xl p-8 pb-6  md-mb-none shadow-xl ring-1 ring-slate-200  relative">
               

              <div className="mb-4">
                <h3 className="font-display leading-6 md:leading-7 font-bold text-2xl text-slate-800">
                Get a FREE Web Design &amp; SEO Consultation
                </h3>
                <p className="text-slate-600 mb-2 leading-6 mt-1">
                No Obligation Estimate, Expert Advice
                </p>
              </div>
              <ContactForm hideLabels={true} showPlaceholders={true} footerContained={true}/>
              </div>
            </div>

             
            </div>
            </div>
  </div>
    <TrophyBanner heading={'#1 in Customer Satisfaction'} subheading={'Top Rated Digital Marketing Agency in Sacramento'}/>
    </>

  )
}

export default HomePageHero
