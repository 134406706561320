import React from 'react'
import styled from 'styled-components'

const LogoContainer = styled.div`
 
width:100%;

 

`
const SCDLogo = ({fill}) => (
  <LogoContainer>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 1168.7 322.4"
      fill={fill?fill: ""}
    >
     <g transform="matrix(1.33333 0 0 -1.33333 0 1200)">
        <g>
          <defs>
            <path id="SVGID_1_" d="M-495.1 177.8H1371.6V1377.8H-495.1z"></path>
          </defs>
          <title>Solid Construction and Design Logo</title>

          <clipPath>
            <use overflow="visible" xlinkHref="#SVGID_1_"></use>
          </clipPath>
          <g className="st1">
            <g transform="translate(644.908 354.56)">
              <path
                d="M-261.7 327.8c-.3-3-1.3-5.4-3.2-7.2-1.9-1.8-4.3-2.7-7.3-2.7-3.4 0-6.1 1.2-8.1 3.5s-3.1 5.4-3.1 9.3c0 3.6 1 6.7 3 9.1 2 2.5 4.8 3.7 8.4 3.7 2.5 0 4.7-.7 6.7-2.2 2-1.5 3.1-3.4 3.4-5.8h-2.3c-.4 1.8-1.2 3.2-2.6 4.3-1.4 1.1-3.1 1.6-5.1 1.6-2.7 0-4.9-1-6.5-3-1.7-2-2.5-4.6-2.5-7.9 0-3.3.8-5.9 2.5-7.8 1.6-2 3.8-2.9 6.5-2.9 2.4 0 4.2.7 5.6 2.2 1.4 1.5 2.2 3.4 2.5 5.8h2.1z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(662.85 368.205)">
              <path
                d="M-261.7 327.8c-2.7 0-4.9-1-6.6-3-1.7-2-2.5-4.6-2.5-7.8 0-3.3.8-5.9 2.5-7.9s3.9-3 6.7-3c2.8 0 5 1 6.7 3 1.7 2 2.5 4.6 2.5 7.8s-.8 5.8-2.5 7.8c-1.8 2.1-4.1 3.1-6.8 3.1m0 2c3.4 0 6.2-1.2 8.3-3.6 2.1-2.4 3.2-5.5 3.2-9.3 0-3.8-1.1-6.9-3.2-9.2-2.1-2.3-4.9-3.5-8.4-3.5-3.4 0-6.2 1.2-8.3 3.5-2.1 2.4-3.2 5.5-3.2 9.3 0 3.8 1.1 6.9 3.2 9.3 2.2 2.4 5 3.5 8.4 3.5"
                className="st2"
              ></path>
            </g>
            <g transform="translate(701.486 369.65)">
              <path
                d="M-261.7 327.8v-24.5h-2.6l-11.4 16.4c-1.5 2.2-2.6 3.9-3.2 5.2.1-1.6.2-3.6.2-5.8v-15.8h-2.3v24.5h2.6l11.4-16.6c1.3-1.9 2.3-3.6 3.2-5.1-.1 3.6-.2 5.8-.2 6.5v15.3h2.3z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(727.645 362.603)">
              <path
                d="M-261.7 327.8h-2.3c-.1 1.8-.7 3.2-1.9 4.1-1.2 1-2.8 1.4-4.9 1.4-1.9 0-3.4-.4-4.5-1.2-1.1-.8-1.6-1.9-1.6-3.4 0-.5.1-1 .2-1.4.1-.4.4-.8.7-1 .3-.3.6-.5.9-.7.3-.2.8-.4 1.4-.6.6-.2 1.2-.3 1.5-.4.4-.1 1-.2 1.9-.4.9-.2 1.7-.4 2.2-.5.6-.1 1.2-.3 2-.5s1.3-.5 1.8-.7c.4-.2.9-.5 1.4-.9.5-.3.8-.7 1.1-1.1.3-.4.5-.9.6-1.5.2-.6.3-1.2.3-1.9 0-1.7-.5-3.1-1.4-4.2-.9-1.1-2.1-1.9-3.4-2.3-1.3-.4-2.8-.7-4.4-.7-3 0-5.4.7-7.2 2.2-1.7 1.5-2.6 3.5-2.6 6.2v.2h2.2c0-2.2.7-3.9 2-5 1.4-1.1 3.2-1.7 5.7-1.7 2.1 0 3.8.4 5 1.3 1.2.9 1.8 2.1 1.8 3.7 0 1.5-.6 2.6-1.7 3.3-1.2.7-3.3 1.4-6.5 2.1-1 .2-1.8.4-2.3.6-.5.1-1.2.4-2 .7-.8.3-1.4.7-1.8 1.1a5 5 0 00-1.1 1.6c-.3.7-.5 1.5-.5 2.4 0 2.1.8 3.7 2.3 4.9 1.5 1.2 3.6 1.8 6.2 1.8 2.7 0 4.8-.6 6.4-1.9 1.5-1.2 2.3-3.1 2.5-5.6"
                className="st2"
              ></path>
            </g>
            <g transform="translate(753.184 369.65)">
              <path
                d="M-261.7 327.8v-2h-8.6v-22.5h-2.3v22.5h-8.6v2h19.5z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(761.915 367.656)">
              <path
                d="M-261.7 327.8v-9.7h6.9c1 0 1.9.1 2.6.2.7.1 1.5.3 2.2.7.7.3 1.3.8 1.7 1.5s.6 1.6.6 2.6c0 3.2-2 4.7-6.1 4.7h-7.9zm-2.4 2h11.1c2.3 0 4.2-.5 5.6-1.6 1.4-1.1 2.1-2.7 2.1-5 0-1.6-.4-2.9-1.3-3.9-.8-1-2-1.7-3.5-2.1 1.5-.3 2.5-.8 3.2-1.7.6-.9 1-2.1 1.1-3.8v-.9c0-.6.1-1.1.1-1.5 0-.4.1-.9.2-1.5s.2-1.1.3-1.5c.1-.4.3-.7.5-.9h-2.6c-.2.3-.4.7-.4 1.3-.1.6-.2 1.5-.2 2.5s-.1 1.5-.1 1.6c-.2 2.2-.7 3.7-1.6 4.4-.9.7-2.5 1.1-4.9 1.1h-7.2v-10.9h-2.3v24.4z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(805.467 369.65)">
              <path
                d="M-261.7 327.8v-15.3c0-3.2-.8-5.6-2.4-7.2-1.6-1.7-3.9-2.5-7.1-2.5-3.2 0-5.6.8-7.2 2.5-1.6 1.6-2.4 4.1-2.4 7.3v15.2h2.3v-14.6c0-2.8.5-4.9 1.6-6.3 1.1-1.4 3-2.1 5.8-2.1 1.4 0 2.6.2 3.5.6.9.4 1.7 1 2.2 1.8.5.8.9 1.6 1 2.6.2 1 .3 2.1.3 3.5v14.6h2.4z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(834.89 354.56)">
              <path
                d="M-261.7 327.8c-.3-3-1.3-5.4-3.2-7.2-1.9-1.8-4.3-2.7-7.3-2.7-3.4 0-6.1 1.2-8.1 3.5s-3.1 5.4-3.1 9.3c0 3.6 1 6.7 3 9.1 2 2.5 4.8 3.7 8.4 3.7 2.5 0 4.7-.7 6.7-2.2 2-1.5 3.1-3.4 3.4-5.8h-2.3c-.4 1.8-1.2 3.2-2.6 4.3-1.4 1.1-3.1 1.6-5.1 1.6-2.7 0-4.9-1-6.5-3-1.7-2-2.5-4.6-2.5-7.9 0-3.3.8-5.9 2.5-7.8 1.6-2 3.8-2.9 6.5-2.9 2.4 0 4.2.7 5.6 2.2 1.4 1.5 2.2 3.4 2.5 5.8h2.1z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(859.468 369.65)">
              <path
                d="M-261.7 327.8v-2h-8.6v-22.5h-2.3v22.5h-8.6v2h19.5z"
                className="st2"
              ></path>
            </g>
            <path d="M606.6 673h-2.3v24.5h2.3V673z" className="st2"></path>
            <g transform="translate(887.826 368.205)">
              <path
                d="M-261.7 327.8c-2.7 0-4.9-1-6.6-3s-2.5-4.6-2.5-7.8c0-3.3.8-5.9 2.5-7.9s3.9-3 6.7-3 5 1 6.7 3c1.7 2 2.5 4.6 2.5 7.8s-.8 5.8-2.5 7.8c-1.8 2.1-4.1 3.1-6.8 3.1m0 2c3.4 0 6.2-1.2 8.3-3.6 2.1-2.4 3.2-5.5 3.2-9.3 0-3.8-1.1-6.9-3.2-9.2-2.1-2.3-4.9-3.5-8.4-3.5-3.4 0-6.2 1.2-8.3 3.5-2.1 2.4-3.2 5.5-3.2 9.3 0 3.8 1.1 6.9 3.2 9.3 2.2 2.4 5 3.5 8.4 3.5"
                className="st2"
              ></path>
            </g>
            <g transform="translate(926.461 369.65)">
              <path
                d="M-261.7 327.8v-24.5h-2.6l-11.4 16.4c-1.5 2.2-2.6 3.9-3.2 5.2.1-1.6.2-3.6.2-5.8v-15.8h-2.3v24.5h2.6l11.4-16.6c1.3-1.9 2.3-3.6 3.2-5.1-.1 3.6-.2 5.8-.2 6.5v15.3h2.3z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(955.955 359.75)">
              <path
                d="M-261.7 327.8c2.6 1.5 3.9 3.1 3.9 4.9 0 .9-.3 1.7-1 2.3-.6.6-1.4.9-2.4.9s-1.8-.3-2.4-.9c-.6-.6-1-1.3-1-2.2 0-.6.1-1.2.4-1.8.3-.6.7-1.2 1.3-1.8.6-.6 1-1.1 1.2-1.4m-.7-2.3c-1.8-1.1-2.9-2-3.5-2.5-1.1-1.1-1.6-2.2-1.6-3.5 0-1.4.5-2.5 1.5-3.5s2.3-1.4 3.8-1.4c2.4 0 4.3 1.2 5.7 3.5l-5.9 7.4zm9.8-1.4c0-.9-.2-1.9-.5-3s-.6-2-1-2.7l4.4-5.2h-2.8l-2.8 3.4c-1.7-2.6-4.1-3.9-7.1-3.9-2.2 0-3.9.6-5.3 1.8-1.4 1.2-2 2.8-2 4.8 0 3 2 5.4 6.1 7.5-.6.8-1.1 1.4-1.4 1.8-.3.5-.7 1.1-1 1.9-.4.8-.5 1.5-.5 2.2 0 1.4.5 2.6 1.5 3.5 1 .9 2.3 1.4 4 1.4 1.6 0 2.8-.5 3.8-1.4 1-.9 1.5-2.1 1.5-3.5s-.4-2.6-1.3-3.5c-.9-1-2-1.9-3.5-2.9l5.2-6.4c.5 1.3.8 2.6.8 4h1.9z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(990.328 367.656)">
              <path
                d="M-261.7 327.8v-20.5h6.2c6 0 9 3.5 9 10.6 0 3.1-.7 5.6-2.2 7.3-1.5 1.8-3.6 2.6-6.4 2.6h-6.6zm-2.4 2h8.9c3.7 0 6.4-1.1 8.2-3.2 1.8-2.1 2.7-5 2.7-8.6 0-3.7-.9-6.8-2.6-9.1-1.8-2.4-4.5-3.6-8.1-3.6h-9.1v24.5z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(1032.676 369.65)">
              <path
                d="M-261.7 327.8v-2h-14.6V317h13.7v-2h-13.7v-9.7h14.8v-2h-17.1v24.5h16.9z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(1057.288 362.603)">
              <path
                d="M-261.7 327.8h-2.3c-.1 1.8-.7 3.2-1.9 4.1-1.2 1-2.8 1.4-4.9 1.4-1.9 0-3.4-.4-4.5-1.2-1.1-.8-1.6-1.9-1.6-3.4 0-.5.1-1 .2-1.4s.4-.8.7-1c.3-.3.6-.5.9-.7.3-.2.8-.4 1.4-.6.6-.2 1.2-.3 1.5-.4.4-.1 1-.2 1.9-.4.9-.2 1.7-.4 2.2-.5.6-.1 1.2-.3 2-.5s1.3-.5 1.8-.7c.4-.2.9-.5 1.4-.9.5-.3.8-.7 1.1-1.1.3-.4.5-.9.6-1.5.2-.6.3-1.2.3-1.9 0-1.7-.5-3.1-1.4-4.2-.9-1.1-2.1-1.9-3.4-2.3-1.3-.4-2.8-.7-4.4-.7-3 0-5.4.7-7.2 2.2-1.7 1.5-2.6 3.5-2.6 6.2v.2h2.2c0-2.2.7-3.9 2-5 1.4-1.1 3.2-1.7 5.7-1.7 2.1 0 3.8.4 5 1.3 1.2.9 1.8 2.1 1.8 3.7 0 1.5-.6 2.6-1.7 3.3-1.2.7-3.3 1.4-6.5 2.1-1 .2-1.8.4-2.3.6-.5.1-1.2.4-2 .7-.8.3-1.4.7-1.8 1.1a5 5 0 00-1.1 1.6c-.3.7-.5 1.5-.5 2.4 0 2.1.8 3.7 2.3 4.9 1.5 1.2 3.6 1.8 6.2 1.8 2.7 0 4.8-.6 6.4-1.9 1.5-1.2 2.3-3.1 2.5-5.6"
                className="st2"
              ></path>
            </g>
            <path d="M806.8 673h-2.3v24.5h2.3V673z" className="st2"></path>
            <g transform="translate(1098.742 357.618)">
              <path
                d="M-261.7 327.8v-12.4h-1.5l-.4 3.5-.1 1.1c-.7-1.6-1.9-2.9-3.4-3.8s-3.2-1.4-5.2-1.4c-3.4 0-6.2 1.2-8.3 3.5-2.1 2.3-3.2 5.4-3.2 9.1 0 3.9 1.1 7 3.2 9.4 2.1 2.4 4.9 3.6 8.4 3.6 2.6 0 4.9-.7 6.8-2.1 1.9-1.4 3-3.4 3.4-5.9h-2.2c-.3 1.9-1.3 3.4-2.7 4.5-1.5 1.1-3.2 1.6-5.2 1.6-2.8 0-5.1-1-6.8-3-1.7-2-2.5-4.7-2.5-8 0-3.2.9-5.8 2.6-7.8 1.7-1.9 4-2.9 6.8-2.9 2.2 0 4 .6 5.6 1.9 1.5 1.3 2.4 3 2.8 5.1.1.6.1 1.4.1 2.2h-8.4v1.9h10.2z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(1126.584 369.65)">
              <path
                d="M-261.7 327.8v-24.5h-2.6l-11.4 16.4c-1.5 2.2-2.6 3.9-3.2 5.2.1-1.6.2-3.6.2-5.8v-15.8h-2.3v24.5h2.6l11.4-16.6c1.3-1.9 2.3-3.6 3.2-5.1-.1 3.6-.2 5.8-.2 6.5v15.3h2.3z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(725.843 403.773)">
              <path
                d="M-261.7 327.8h-102.8v14.7h88.1v14.7h-88.1v44.1h102.8v-14.7h-88.1v-14.7h88.1v-44.1z"
                className="st2"
              ></path>
            </g>
            <path
              d="M499.8 746.3h73.4v44.1h-73.4v-44.1zM485.1 805h102.8v-73.4H485.1V805z"
              className="st2"
            ></path>
            <g transform="translate(870.657 477.21)">
              <path
                d="M-261.7 327.8h14.7V269h88.1v-14.7h-102.8v73.5z"
                className="st2"
              ></path>
            </g>
            <path d="M725.5 805h14.7v-73.4h-14.7V805z" className="st2"></path>
            <path
              d="M849.3 790.3h-73.4v-44.1h73.4v44.1zm-88.1-58.7V805h88.1l14.7-14.7v-44.1l-14.7-14.7-88.1.1z"
              className="st2"
            ></path>
            <g transform="translate(456.767 366.488)">
              <path
                d="M-261.7 327.8h103.6v98.1l-103.6 55.5V327.8zm124.7-21.2h-145.9v210.1l145.9-78.2V306.6z"
                className="st2"
              ></path>
            </g>
            <g transform="translate(294.562 424.346)">
              <path
                d="M-261.7 327.8v39.8l124.8 68.9v22.3l-145.9-80v-84.2L-158 359l.6-92h-125.4v-18.1l145.9-.1v143.4l-124.8-64.4z"
                className="st2"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </LogoContainer>
)
export default SCDLogo
