import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Button from './button' 
import ContactForm from './contact-form'
const ReactMarkdown = require('react-markdown')

const StarIcon = (
  <svg
    className="w-8 h-8"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
)

const Heading = styled.h2.attrs({
  className: 'text-2xl md:text-2xl lg:text-3xl font-semibold leading-tight relative font-display',
})``


const SectionOuterContainer = styled.section.attrs({
  className: 'apply-even-odd-style bg-white py-16',
})`
  
  p {
    margin-bottom: 1em;
    
  } 
`
 

const ImageContainer = styled.div.attrs({
  className: 'w-full md:w-1/2 md:px-2',
})``
const HeadingCopyContainer = styled.div``

const ContentSection = ({
  heading,
  copy,
  addImageContainer,
  image,
  layout,
  ctaText,
  ctaURL,
  buttonText,
  topHeading,
  topSubheading,
  showStars,
  subheading,
  buttonLink,
  buttonHelperText,
  cssClass,
  isCityPage,
  renderCopyAsHtml,
  showRightSidebar, 
  sidebarArray
}) => {
  let imagePosition
  let headingCopyPosition
  let imagePadding
  let centerContent
  switch (layout) {
    case 'Image Right':
      imagePosition = 'order-first md:order-last'
      headingCopyPosition = 'md:order-first'
      imagePadding = 'md:ml-12 mb-6'
      break
    case 'Image Left':
      imagePosition = 'order-first'
      headingCopyPosition = 'order-last'
      imagePadding = 'md:mr-12 mb-6'
      break
    case 'Image Background':
      centerContent = true
      imagePosition = ''
      headingCopyPosition = 'text-center'
      imagePadding = ''
      break 
    default:
      imagePosition = 'order-last'
      headingCopyPosition = 'order-first'
      imagePadding = 'md:ml-12 mb-6'
      break
  }
  let hasImage = image === null ? false : true
  if(layout === 'big_center') {
    return <div>
      <div className="pt-10 mx-auto max-w-screen-xl px-6 sm:px-6 md:pt-16 lg:pt-20">
          <div className="text-center">
          {subheading ? <span className="block font-medium leading-loose mb-2 text-blue-500 tracking-wider uppercase relative">{subheading}</span> : null}
            <h2 className=" tracking-tight leading-10 font-extrabold text-gray-900   text-2xl sm:leading-none md:text-3xl font-display">
              {heading}
            </h2>
         
            <ReactMarkdown source={copy} className={`prose markdown-content leading-relaxed relative mt-3 max-w-md mx-auto text-base  sm:text-lg md:mt-3 md:text-xl md:max-w-3xl pb-4 ${centerContent ? 'text-white' : 'text-gray-600'}`} />
              {buttonLink && buttonText ? <><Button
                hasArrow={true}
                className={
                  'text-lg bg-blue-500 hover:bg-blue-700 text-white inline relative'
                }
                to={buttonLink}
              >
                {buttonText}
              </Button><span className="block text-xs text-gray-500 py-2 relative">{buttonHelperText}</span></>: null}


          </div>
        </div>
        <div className="relative">
        <div className="absolute inset-0 flex flex-col">
          <div className="flex-1"></div>
          <div className="flex-1 w-full bg-gray-200"></div>
        </div>
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 ">
    
        <div className={'w-full relative mx-auto md:w-3/4 md:px-2'}>
            <img src={image && image.file && image.file.url} alt={heading} />
          </div>
          
 
        </div>
      </div>
    </div>
  }
  const BodyContent = () => (
    <div>
    {showStars ?  <div className={`flex text-yellow-500 mx-auto mb-5 ${isCityPage ? '' : 'justify-center'}`}>
    {StarIcon}
    {StarIcon}
    {StarIcon}
    {StarIcon}
    {StarIcon}
  </div> : null}
    <h2 className="font-bold text-2xl  md:text-3xl  text-center font-display text-gray-900 " >{topHeading}</h2>
    <h3 className="font-mediun text-lg md:text-xl  text-center font-display text-gray-700 mb-2">{topSubheading}</h3>
 
    <div className=" flex flex-wrap items-center">
    <HeadingCopyContainer
      className={
        (hasImage === true ? `w-full md:w-1/2 ${centerContent ? 'text-white text-center mx-auto py-6' : ''}` : '') +
        ' ' +
        headingCopyPosition
      }
    >
      {renderCopyAsHtml ? null : <>{subheading ? <span className="block font-medium leading-loose mb-2 text-blue-500 tracking-wider uppercase relative">{subheading}</span> : null}
      <Heading>{heading}</Heading></>}


      {renderCopyAsHtml ?  <span className="prose markdown-content py-4 leading-relaxed relative" dangerouslySetInnerHTML={{ __html:  copy}}/> :  <ReactMarkdown source={copy} className={`prose markdown-content py-4 leading-relaxed relative ${centerContent ? 'text-white' : 'text-gray-700'}`} /> }
     

     
     
      {buttonLink && buttonText ? <><Button
        hasArrow={true}
        className={
          'text-lg bg-blue-500 hover:bg-blue-700 text-white inline relative'
        }
        to={buttonLink}
      >
        {buttonText}
      </Button><span className="block text-xs text-gray-500 py-2 relative">{buttonHelperText}</span></>: null}

    </HeadingCopyContainer>

    {image && image.file && image.file.contentType === 'image/svg+xml'  && !centerContent ? (
      <ImageContainer className={imagePosition}>
        <img src={image.file.url} alt={heading} className="p-4"/>
      </ImageContainer>
    ) : hasImage && !centerContent  ? (
      <ImageContainer className={`${imagePosition} `}>
        <Img height={'100%'} className={`${imagePadding} ${addImageContainer ? 'shadow-md md:shadow-xl rounded' : ''}`} fluid={image.fluid} />
      </ImageContainer>
    ) : null}
  </div></div>
  )
  return (
    <SectionOuterContainer className={`${cssClass ? cssClass : ''} ${centerContent ? 'bg-cover h-auto relative' : ''}`}> 
       {centerContent && hasImage ? (
          <Img fluid={image.fluid} className="heroAreaImg z-0 w-full left-0 absolute top-0 h-full" />
        ) : null}

      <div className="container mx-auto px-6">

      {isCityPage || showRightSidebar ? <div className="flex flex-wrap">
        <div className="w-full md:w-2/3 p-0 md:pr-16">
          <BodyContent/>
          </div>
          <div className="w-full md:w-1/3 p-0 md:pl-6">
          <div className="sticky top-0  z-1 ">
            <div className="h-4"></div>
            <div className="border-radius p-10 shadow-lg sticky top-0 bg-white rounded-xl border-t-4 border-blue-500">
                    
            <h3 class="text-left font-bold font-display text-xl md:text-2xl">Start with a FREE Quote!</h3>
            <h3 class="font-normal text-gray-600 text-left mb-5 text-md">No Obligation Estimate, Expert Advice

</h3>
                
                    <div>
                    <ContactForm showLabels={false}/>
                    </div>
                  </div>
        
          </div>  </div>
        </div> : <BodyContent/>}
        


      
      </div>
    </SectionOuterContainer>
  )
}

ContentSection.propTypes = {
  heading: PropTypes.string,
  copy: PropTypes.string,
  topHeading: PropTypes.string,
  topSubheading: PropTypes.string,
  showStars: PropTypes.bool,
  addImageContainer: PropTypes.bool,
  layout: PropTypes.string,
  image: PropTypes.object,
  ctaText: PropTypes.string,
  ctaURL: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  buttonHelperText: PropTypes.string,
  cssClass: PropTypes.string,
  renderCopyAsHtml: PropTypes.bool,
  showRightSidebar: PropTypes.bool

}

ContentSection.defaultProps = {
  heading: ``,
  topHeading: null,
  topSubheading: null,
  showStars: false,
  copy: ``,
  image: null,
  layout: ``,
  ctaText: ``,
  ctaURL: ``,
  buttonLink: null,
  buttonText: null,
  buttonHelperText: null,
  cssClass: ``,
  addImageContainer: false,
  renderCopyAsHtml: false,
  showRightSidebar: false
}

export default ContentSection
