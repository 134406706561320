import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Slider from 'react-slick'
import Img from 'gatsby-image'
import { StarIcon } from '@heroicons/react/solid'

export const ReviewComponent = props => {
  let { body, reviewer, companyName, avatar, companyLogoSvg } = props

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="relative">
        {companyLogoSvg && companyLogoSvg.companyLogoSvg ? (
          <span
            className="svg-logo-reviews"
            dangerouslySetInnerHTML={{ __html: companyLogoSvg.companyLogoSvg }}
          />
        ) : null}

        <div className="flex mb-2">
          <StarIcon className="text-yellow-500 w-6 h-6" />
          <StarIcon className="text-yellow-500 w-6 h-6" />
          <StarIcon className="text-yellow-500 w-6 h-6" />
          <StarIcon className="text-yellow-500 w-6 h-6" />
          <StarIcon className="text-yellow-500 w-6 h-6" />
        </div>

        <blockquote className="mt-2">
          <p className="text-sm md:text-base leading-6 md:leading-7 text-gray-700">
            {body}
          </p>

          <footer className="mt-8">
            <div className="flex items-center">
              <div className="md:flex-shrink-0">
                <Img
                  className="mx-auto h-10 w-10 rounded-full"
                  fluid={avatar.fluid}
                />
              </div>
              <div className="ml-4 text-left">
                <div className="text-base font-medium text-gray-900 ">
                  {reviewer}
                </div>

                <div className="text-sm font-medium text-gray-500">
                  {companyName}
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  )
}
var settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ReviewsSlider = () => (
  <StaticQuery
    query={graphql`
      query SiteTestimonials {
        allContentfulTestimonial(limit: 4) {
          edges {
            node {
              reviewerName
              title
              review {
                review
              }

              companyName
              avatar {
                fluid(maxWidth: 100) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="bg-gray-50">
          <div className="py-12 overflow-hidden md:py-20 container px-4 md:px-6 mx-auto">
            <h2 className="font-bold text-2xl md:text-3xl text-center font-display text-gray-800 leading-tight mb-6">
              What Our Clients Are Saying
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {data.allContentfulTestimonial.edges.map((item, i) => (
                <ReviewComponent
                  key={i}
                  title={item.node.title}
                  body={item.node.review.review}
                  reviewer={item.node.reviewerName}
                  companyName={item.node.companyName}
                  avatar={item.node.avatar}
                  companyLogoSvg={null}
                />
              ))}
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default ReviewsSlider
