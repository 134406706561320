import React from 'react' 
import GVDLogo from '../assets/client-logos/gvd'
import KRWLogo from '../assets/client-logos/krw'
import HouseIdeaLogo from '../assets/client-logos/houseidea'
import SCDLogo from '../assets/client-logos/solid'
import USQLogo from '../assets/client-logos/usq'
import WCHPLogo from '../assets/client-logos/wchp'

 
const TrustedBrandsBanner = () => (
  <div className="bg-brand-500 py-12 lg:p-12">
    <div className="container mx-auto px-4">
      <h2 className="text-center text-xl md:text-3xl font-bold leading-tight text-white  mb-6">Servicing Local Businesses Nationwide

</h2>
      <div className="flex flex-wrap items-center">
      <div className="w-1/3 md:w-1/6 p-4">
          <GVDLogo fill={"#fff"}/>
        </div>
        <div className="w-1/3 md:w-1/6 p-4">
          <USQLogo fill={"#fff"}/>
        </div>
      
        <div className="w-1/3 md:w-1/6 p-4">
          <HouseIdeaLogo fill={"#fff"} />
        </div>
       
        <div className="w-1/3 md:w-1/6 p-4">
          <SCDLogo fill={"#fff"}/>
        </div>
        <div className="w-1/3 md:w-1/6 p-4">
          <KRWLogo fill={"#fff"}/>
        </div>
        <div className="w-1/3 md:w-1/6 p-4">
          <WCHPLogo fill={"#fff"}/>
        </div>
      </div>
    </div>
  </div>
)

export default TrustedBrandsBanner
