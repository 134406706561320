import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const ButtonOuterLink = styled(Link).attrs({className: 'appearance-none inline-flex rounded-full focus:outline-none font-semibold px-6 py-2 '})`
}
`
const ButtonOuterNoLink = styled.button.attrs({className: 'appearance-none inline-flex rounded-full focus:outline-none font-semibold px-6 '})`
}
`

const ArrowRightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="18" height="18"  stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M9 5l7 7-7 7" />
</svg>
 )

const Button = ({ to, title, className, hasArrow, children, type}) => {
  let finalItem = null
  if (to !== null) {
    finalItem = <ButtonOuterLink type={type}  title={title} to={to} className={'cursor-pointer ' + className + (hasArrow ? ' has-arrow-hover':'')}><span className="flex mx-auto  items-center"><span className="btn-content theme-font">{children}</span>{hasArrow ? <ArrowRightIcon/>: ''}</span></ButtonOuterLink>
  } else {
finalItem = <ButtonOuterNoLink type={type}  title={title} className={'cursor-pointer ' + className + (hasArrow ? ' has-arrow-hover':'')}><span className="flex mx-auto items-center"><span className="btn-content theme-font">{children}</span>{hasArrow ? <ArrowRightIcon/>: ''}</span></ButtonOuterNoLink>
  }
  return finalItem}

Button.propTypes = {
  to: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  className:PropTypes.string,
  hasArrow: PropTypes.bool
}

Button.defaultProps = {
  to: null,
  type: `button`,
  title: ` `,
  className: ` `,
  hasArrow: false,
}

export default Button
