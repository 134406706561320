import PropTypes from 'prop-types'
import React from 'react'
import GridContentItem from './grid-content-item'
import PricingPackageItem from './pricing-package-item'
import {parseContent} from '../helpers/city-pages'

let GridContentSection = ({
  checkContentForCityTag = true,
  heading,
  subheading,
  copy,
  mobileItemsPerRow,
  desktopItemsPerRow,
  items,
  city = "Sacramento",
  citySlug = false
}) => {

  if(checkContentForCityTag) {
    heading = parseContent(heading, city)
    subheading = parseContent(subheading, city)
    copy = parseContent(copy, city)
  }


  const regexSingleStar = /\*(.*?)\*/g;

  let list = null
  let nonListSentence = null
  if(copy) {
  
    list = copy.split('-')
    nonListSentence = list[0]
    list.splice(0, 1)
    for (let i = 0; i < list.length; i++) {
      if (list[i].charAt(0) === ' ') {
        list[i] = list[i].slice(1, list[i].length)
      }
    }
  }
 


  return (
    <section className="apply-even-odd-style bg-white ">
    <div className="container mx-auto  px-5 md:px-8 py-12 md:py-20 relative overflow-hidden">
      
    <div className="relative">
    <h2 className="font-bold text-2xl md:text-4xl text-center font-display text-gray-800 mb-2 leading-tight"
          dangerouslySetInnerHTML={{ __html: heading.replace(
            regexSingleStar, function (x) {
              return "<span class='text-green-600'>" + regexSingleStar.exec(heading)[1] + "</span>";
            }
          ) }}
        />  
        <h3 className="font-semibold text-xl md:text-2xl text-center font-display text-gray-700 mb-2">{subheading}</h3>
        {copy ? <p className="text-center flex justify-center">{nonListSentence ? (<div>{nonListSentence}</div>) : null}  {list.map((item, i) => (
              <span key={i} className="py-3 flex flex-wrap font-display text-gray-700 font-semibold justify-center items-center mr-4"> 
                <svg className="h-6 w-6 p-1 bg-green-500 rounded-full text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>

                 <span className={`ml-2 text-lg`}>{item}</span></span>
            ))}</p> : null} 

      <div className={`mt-10 grid grid-cols-${mobileItemsPerRow} sm:grid-cols-2 gap-10 md:grid-cols-${desktopItemsPerRow}`}>
        {items && items.map(item => {
      

        let title = item.title
        let description = item.description ? item.description.description : null
        let buttonLink = item.buttonLink

        if(checkContentForCityTag) {
          title = parseContent(title, city)
          description = parseContent(description, city) 

          if(buttonLink == "/sacramento-web-design/" && city != "Sacramento" && citySlug) {
            buttonLink = `/${citySlug}-web-design/`
          }
          if(buttonLink == "/sacramento-seo/" && city != "Sacramento" && citySlug) {
            buttonLink = `/${citySlug}-seo/`
          }
      


        }
      


          if(item.__typename === "ContentfulGridItem"){
            return <GridContentItem key={item.id} item={item} svgIcon={item && item.svgIcon && item.svgIcon.svgIcon} title={title} copy={item.copy ? item.copy.copy : null} layout={item.layout} />
          }
          else if(item.__typename === "ContentfulPricingPackage") { 
            return <PricingPackageItem 
            key={item.id} 
            buttonText={item.buttonText}
             whatsIncluded={item.whatsIncluded && item.whatsIncluded.whatsIncluded} 
             image={item.image} 
             buttonLink={buttonLink} 
             startingAtPrice={item.startingAtPrice} 
             strikeOutPrice={item.strikeOutPrice} 
             notes={item.notes} item={item} 
             svgImage={item && item.svgImage && item.svgImage.svgImage} 
             title={title} 
             description={description}  />
       
          } else {
            return null
          }
        }
          )}
      </div>

    </div>
    </div>
    </section>
  )
}

//grid grid-cols-1 sm:grid-cols-2 gap-12 md:grid-cols-3 grid-cols-3 sm:grid-cols-3

GridContentSection.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  copy:  PropTypes.string,
  layout: PropTypes.string,
  mobileItemsPerRow: PropTypes.number,
  desktopItemsPerRow: PropTypes.number,
  items: PropTypes.array,
}

GridContentSection.defaultProps = {
  heading: '',
  subheading: '',
  copy: '',
  layout: 'Heading Copy Top',
  mobileItemsPerRow: 1,
  desktopItemsPerRow: 3,
  items: [],
}

export default GridContentSection
