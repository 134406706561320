import PropTypes from 'prop-types'
import React from 'react'
import TrustedBrandsBanner from './trustedBrandsBanner'
import HomePageHero from './predefined-modules/homepageHero'
import ReviewsSlider from './customer-reviews'

const PredefinedComponentController = ({
  componentID, moduleId
}) => {
  
  switch (moduleId) {
    //Trused by Local Companies Section
    case 'TrustedBrandsBanner':
      return <TrustedBrandsBanner/>
    case 'customerReviews':
      return <ReviewsSlider/>
    case 'ourProcessDesign':
     return null
    case 'HomePageCustomHeroArea':
    return <HomePageHero/> 
    default:
      return <p className="font-display hidden">{moduleId}</p>
  }
  
}

PredefinedComponentController.propTypes = {
  componentName: PropTypes.string
}

PredefinedComponentController.defaultProps = {
  componentName: null
}

export default PredefinedComponentController
