import React from 'react'
import PropTypes from 'prop-types'

function TrophyBanner({ heading, subheading }) {
  return (
    <div className="bg-yellow-500">
      <div className="container flex items-center mx-auto  px-4 md:px-8 ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 501.551 501.551"
          className="-my-32 relative  w-24 mr-3"
        >
          <polygon
            fill="#FF7058"
            points="296.751,412.735 204.8,412.735 220.473,379.298 234.057,347.951 267.494,347.951   281.078,379.298 "
          />
          <path
            fill="#FFD15C"
            d="M355.265,412.735h-208.98c-11.494,0-20.898,9.404-20.898,20.898v67.918h249.731v-67.918  C376.163,422.139,366.759,412.735,355.265,412.735z"
          />
          <path
            fill="#F1543F"
            d="M281.078,379.298c-8.359,4.18-16.718,8.359-25.078,11.494l-5.224,1.045l-5.225-2.09  c-8.359-3.135-16.718-7.314-25.078-11.494l13.584-31.347h32.392L281.078,379.298z"
          />
          <g>
            <path
              fill="#F8B64C"
              d="M0,57.469v42.841C0,204.8,64.784,294.661,155.69,332.278c29.257,12.539,61.649,18.808,95.086,18.808   s65.829-6.269,95.086-18.808c90.906-37.616,155.69-127.478,155.69-231.967V57.469H0z M459.755,100.31   c0,50.155-17.763,96.131-48.065,132.702c-38.661,45.975-96.131,76.278-160.914,76.278s-122.253-29.257-160.914-76.278   c-29.257-35.527-48.065-82.547-48.065-132.702v-1.045H458.71v1.045H459.755z"
            />
            <path
              fill="#F8B64C"
              d="M413.78,0H87.771c-7.314,0-12.539,6.269-12.539,12.539c0,7.314,5.224,13.584,12.539,13.584H413.78   c7.314,0,12.539-6.269,12.539-12.539C426.318,6.269,421.094,0,413.78,0z"
            />
          </g>
          <path
            fill="#FFD15C"
            d="M413.78,26.122v112.849c0,104.49-64.784,199.576-163.004,237.192  c-98.22-38.661-163.004-132.702-163.004-238.237V26.122H413.78z"
          />
          <polygon
            fill="#FFFFFF"
            points="250.776,73.143 272.718,140.016 342.727,140.016 286.302,181.812 307.2,248.686   250.776,206.89 194.351,248.686 215.249,181.812 158.824,140.016 228.833,140.016 "
          />
          <rect
            x="87.771"
            y="26.122"
            fill="#FFC952"
            width="324.963"
            height="15.673"
          />
          
        </svg>
        <div className="py-5 flex flex-wrap  items-center">
        <h2 className="text-xl lg:text-2xl  font-display font-bold text-white mr-2">{heading}</h2>
        <span className="block text-lg md:text-xl lg:text-2xl font-display text-white">{subheading}</span>
        </div>
        
      </div>
    </div>
  )
}

TrophyBanner.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
}

TrophyBanner.defaultProps = {
  heading: '',
}

export default TrophyBanner
