import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image' 
import Button from './button'

const PricingPackageItem = ({ item, svgImage, image, title, description,whatsIncluded, buttonText, buttonLink,startingAtPrice, strikeOutPrice, notes}) => {
  const regexSingleStar = /\*(.*?)\*/g

  let whatsIncludedList = null 
  if(whatsIncluded && whatsIncluded.length > 0) { 
    whatsIncludedList = whatsIncluded.split('-') 
    whatsIncludedList.splice(0, 1) 
  }

 
    return (
      <article className={`shadow rounded-lg overflow-hidden bg-white`}>
       
          {image? <Img fluid={image.fluid} style={{maxHeight:'200px'}} objectFit="cover"
      objectPosition="50% 50%"
      alt="" /> : (svgImage ?  <div
          dangerouslySetInnerHTML={{ __html: svgImage }}
        /> : null)}
        <div className={`px-6 py-4 pb-6`}>
          <div className="pb-3">
          <h3
          className="font-display font-semibold text-xl lg:text-2xl my-2"
          dangerouslySetInnerHTML={{
            __html: title.replace(regexSingleStar, function(x) {
              return (
                "<span class='text-blue-600'>" +
                regexSingleStar.exec(title)[1] +
                '</span>'
              )
            }),
          }}
        />
         <p className="text-gray-700">{description}</p>
          </div>
      

         {whatsIncluded ? <div className="py-3">
           <p className="font-bold font-display text-green-600 text-sm tracking-wider uppercase mb-1">What's included</p> 
           {whatsIncludedList.map((item, i) => (
           <span key={i} className=" flex font-display font-semibold items-center py-1 text-gray-700"> 
                <svg className="h-6 w-6 p-1 text-green-500 rounded-full bg-green-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>

                 <span className={`ml-2 text-base`}>{item}</span></span>))}
         
           </div> : null}


        <div className="py-3">
       
        {/* <p className="block font-display text-gray-800  text-lg font-medium">Starting at {strikeOutPrice ? (<span className="line-through text-gray-700 mr-1">{strikeOutPrice}</span>) : null}<span className="font-semibold text-xl">{startingAtPrice}</span></p> */}
       {/* {notes ? <p className="text-sm text-gray-600">{notes}</p> : null} */}
       </div>
        {buttonLink ?   <Button
        to={buttonLink}
          hasArrow={true}
          className={
            'bg-blue-500 hover:bg-blue-700 text-white block text-center py-2'
          }
        >
          {buttonText}
        </Button> : null}
        </div>
      </article>
    )
  }  
 

PricingPackageItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  svgImage: PropTypes.string,
  whatsIncluded: PropTypes.string,
  startingAtPrice: PropTypes.string,
  strikeOutPrice: PropTypes.string,
  notes: PropTypes.string,
  buttonText:  PropTypes.string,
  buttonLink: PropTypes.string,
}

PricingPackageItem.defaultProps = {
  title: 'Pricing Item',
  description: '',
  svgImage: null,
  whatsIncluded: [],
  startingAtPrice: '',
  strikeOutPrice: '',
  notes: '',
  buttonText: null,
  buttonLink: null,
}
export default PricingPackageItem
