import React from 'react'
import { TOTAL_REVIEW_COUNT, REVIEW_AVG } from '../consts'
export const StarIcon = (
  <svg
    className="w-6 h-6"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
)
function HeroRatingStars({dark}) {
  
  return (
    <div className={`${dark ? '' : 'bg-orange-100'}  text-gray-800 font-semibold flex-wrap inline-flex mt-6 rounded-lg py-4`}>
      <div className="flex text-yellow-500">
        {StarIcon}
        {StarIcon}
        {StarIcon}
        {StarIcon}
        {StarIcon}
      </div>
      <div className={`w-full ${dark ? 'text-white' : 'text-gray-800'} `}>
        <span
          itemScope
          className="rating-desc text-base font-display"
          itemType="https://schema.org/Product"
        >
          <span itemProp="name">WebPerfex</span>{' '}
          <span
            itemScope
            itemProp="aggregateRating"
            itemType="https://schema.org/AggregateRating"
          >
            rated <span itemProp="ratingValue">{REVIEW_AVG}</span>/5 based on{' '}
            <span itemProp="reviewCount">{TOTAL_REVIEW_COUNT}</span> reviews.{' '}
          </span>
        </span>
      </div>
    </div>
  )
}

export default HeroRatingStars
